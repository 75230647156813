import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section>
      <div className="content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1>¡Ups!</h1>
            <p>La página que estás buscando no está aquí...</p>
            <Link to='/'>De vuelta al Inicio</Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
